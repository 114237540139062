.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*******************/
.section-banner {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    min-height: 85vh;
    gap: 5vw;
    /* background-image: url('../../assets/layered-gradient.svg');
    background-size: contain; Adjust the background size as needed */
}

.introduction-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 55%;
    margin-bottom: 5vh;
}

.banner-container {
    display: flex;
}

.banner-img {
    height: 70vh;
    animation: scaleUp 1.5s ease-in-out 0.4s forwards;
    transform: scale(0.85);
}

.slogan {
    animation: slide-in-left 1.5s ease-in-out forwards;
    opacity: 0;
    font-size: 3.2rem;
    color: #282828;
    max-width: 80vw;
}

.sub-slogan {
    animation: slide-in-left-far 1.5s ease-in-out 0.2s forwards;
    opacity: 0;
    font-size: 1.2rem;
    margin-top: 15px;
    color: #444;
    max-width: 80vw;
}

.slogan-coming {
    animation: slide-in-left-farthest 1.5s ease-in-out 0.4s forwards;
    opacity: 0;
    font-size: 1.5rem;
    max-width: 80vw;
    color: #282828;
    margin-top: 55px;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-25%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left-far {
    0% {
        transform: translateX(-30%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left-farthest {
    0% {
        transform: translateX(-35%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scaleUpSm {
    0% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    from {
        transform: scale(0.85);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.introduction-buttons-container {
    display: flex;
    margin-top: 40px;
    max-width: 80vw;
}

.learn-more-btn:hover {
    background-color: #316cf6d9;
    cursor: pointer;
}

.learn-more-btn {
    animation: scaleUpSm 1.5s ease-in-out 0.4s forwards;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #316cf6;
    color: white;
    width: 235px;
    height: 55px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1em;
}

/*******************/

.waitlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20%;
    height: 10vh;
    width: 100%;
}

.waitlist-title {
    color: #444;
    font-size: 1rem;
    width: 50%;
}

.waitlist-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    height: 7vh;
    width: 50%;
}

.waitlist-input {
    background-color: #f9f9f9;
    color: #444;
    border: 2px solid #316cf6;
    height: 90%;
    width: 70%;
    text-indent: 5%;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.waitlist-btn {
    display: flex;
    background-color: #316cf6;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    width: 15%;
    font-size: 0.8em;
    text-decoration: none;
}

/********* Hosts ***********/

.host-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    height: 70vh;
    width: 100vw;
}

.host-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 2.2rem;
}

.host-desc {
    color: #444;
    font-size: 1rem;
    margin-top: 1em;
    max-width: 60%;
}

.host-slider-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.host-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.host-slider-img {
    height: 150px;
}

.host-slider-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

.host-slider-desc {
    color: #444;
    font-size: 1rem;
    flex-wrap: wrap;
    width: 60%;
}

.divider {
    border: 1px dashed #316cf6;
    width: 70%;
    margin: 5rem 0;
}

/********* Renters ***********/

.renter-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    height: 70vh;
    width: 100vw;
}

.renter-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 2.2rem;
}

.renter-desc {
    color: #444;
    font-size: 1rem;
    margin-top: 1em;
    max-width: 60%;
}

.renter-slider-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.renter-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.renter-slider-img {
    height: 150px;
}

.renter-slider-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

.renter-slider-desc {
    color: #444;
    font-size: 1rem;
    flex-wrap: wrap;
    max-width: 60%;
}

/*****************/

.waitlist-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin: 4vh 0;
    height: 10vh;
    width: 100%;
}

.waitlist-mid-title {
    color: #444;
    font-size: 1rem;
    width: 50%;
}

.waitlist-mid-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin: 2vh 0;
    height: 7vh;
    width: 50%;
}

.waitlist-input-mid {
    background-color: #f9f9f9;
    color: #444;
    border: 2px solid #316cf6;
    height: 90%;
    width: 70%;
    text-indent: 5%;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.waitlist-btn-mid {
    display: flex;
    background-color: #316cf6;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    width: 15%;
    font-size: 0.8rem;
    text-decoration: none;
}

/********Parking Lot*********/

.parking-lot-container {
    display: flex;
    height: 80vh;
    width: 100%;
    margin-bottom: 4vh;
    justify-content: center;
    align-items: center;
}

.parking-lot-details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 75%;
    width: 80%;
    overflow: hidden;
    border-radius: 25px;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../assets/parking-lot.jpg');
    background-size: cover;
    gap: 5%;
}

.parking-lot-title {
    font-size: 1.8rem;
    color: #f9e82b;
}

.parking-lot-text {
    font-size: 1rem;
    max-width: 50%;
    color: white;
}

.parking-lot-learn {
    display: flex;
    background-color: #316cf6;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    color: white;
    height: 12%;
    width: 20%;
    font-size: 1rem;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    /* Mobile-specific styles go here */
    .section-banner {
        margin-top: 5vh;
        margin-bottom: 7vh;
        width: 100%;
    }
    .introduction-container {
        max-width: 90%;
    }
    .slogan {
        font-size: 2.8rem;
        text-align: center;
    }
    .sub-slogan {
        font-size: 1rem;
        text-align: center;
    }
    .slogan-coming {
        font-size: 1.2rem;
        text-align: center;
    }
    .introduction-buttons-container {
        justify-content: center;
    }
    .learn-more-btn {
        width: 180px;
        height: 45px;
        font-size: 0.8rem;
    }
    .waitlist-title,
    .waitlist-mid-title {
        font-size: 0.8rem;
        width: 90%;
    }
    .waitlist-form,
    .waitlist-mid-form {
        width: 90%;
        height: 6vh;
    }
    .waitlist-input,
    .waitlist-btn,
    .waitlist-mid-input,
    .waitlist-mid-btn {
        font-size: 0.8rem;
    }
    .waitlist-mid {
        margin: 2vh 0;
    }
    .renter-container {
        padding-top: 6rem;
    }
    .renter-title,
    .host-title {
        font-size: 1.7rem;
    }
    .renter-desc,
    .host-desc {
        max-width: 80%;
        font-size: 0.8rem;
    }
    .renter-slider-title,
    .host-slider-title {
        font-size: 1.2rem;
    }
    .renter-slider-desc,
    .host-slider-desc {
        font-size: 0.8rem;
        padding: 0 30px;
    }
    .parking-lot-container {
        margin-bottom: 0;
    }
    .parking-lot-details {
        width: 90%;
    }
    .parking-lot-title {
        font-size: 1.4rem;
    }
    .parking-lot-text {
        font-size: 0.8em;
        max-width: 80%;
    }
    .parking-lot-learn {
        width: 30%;
        height: 10%;
        font-size: 0.8rem;
    }
}
