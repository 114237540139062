.navbar-container {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 15vh;
    margin: 0 auto;
}

.logo-container {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.logo-img {
    height: 110px;
    cursor: pointer;
}

.choices-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;
    padding-right: 5vw;
}

.link {
    color: #585858;
    font-weight: bold;
    font-size: 20;
}

.link:hover {
    color: #316cf6;
}

@media screen and (max-width: 768px) {
    /* Mobile-specific styles go here */
    .logo-img {
        height: 120px;
        width: 120px;
    }

    .link {
        font-size: 15px;
    }
}
