/* Footer.css */

.footer-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #316cf6;
    color: #fff;
    height: 140px;
    padding-left: 8vw;
}

.footer-logo img {
    width: 100px; /* Adjust the logo size as needed */
    margin-right: 10px;
    cursor: pointer;
}

.footer-divider {
    height: 30px;
    width: 1px;
    background-color: white;
    margin: 0 10px;
}

.footer-email-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.footer-email-container p {
    text-align: start;
    margin: 0;
    color: white;
    font-size: 0.9rem;
}

.footer-email-container a {
    color: #f9e82b;
}

.footer-email {
    text-decoration: underline;
}
