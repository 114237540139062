@import url('https://use.typekit.net/xeo2vqe.css');

.about-page-container {
    display: flex;
    flex-direction: column;
    font-family: 'source-sans-pro', sans-serif;
    justify-content: flex-end;
    margin: 50px auto;
    min-width: 320px;
    max-width: 727px;
    padding: 0 20px;
}

.photos-banner {
    align-items: start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.8%;
    margin: 0 auto;
    max-width: 550px;
}

.photos-img {
    flex: 1;
    max-width: 23.6%;
}

.about-heading {
    color: black;
    font-family: inherit;
    font-size: 46px;
    margin: 50px 0;
}

.about-text p {
    color: black;
    font-family: inherit;
    font-size: 20.5px;
    line-height: 1.25em;
    margin-bottom: 27px;
}
