.contact-page-container {
	display: flex;
	flex-direction: column;
	height: 140vh;
	align-items: center;
	justify-content: flex-end;
}

.contact-page-container h2 {
	color: #316cf6;
	font-size: 2.5rem;
}

.contact-page-container p {
	color: #444;
	font-size: 1rem;
	margin-top: 10px;
}

.contact-form {
	display: flex;
	flex-direction: column;
	height: 80%;
	width: 80%;
	align-items: center;
	justify-content: flex-start;
	padding-top: 35px;
	gap: 3vh;
}

.contact-form div {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.contact-form div label {
	color: #316cf6;
	width: 40%;
	text-align: start;
}

.contact-form div input {
	width: 40%;
	height: 7vh;
	background-color: white;
	border: 2px solid #316cf6;
	border-radius: 10px;
	color: #444;
	font-family: "Nunito Sans", sans-serif;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	box-sizing: border-box;
	padding: 15px;
}

.contact-form div select {
	width: 40%;
	height: 7vh;
	background-color: white;
	border: 2px solid #316cf6;
	border-radius: 10px;
	color: #444;
	font-family: "Nunito Sans", sans-serif;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	box-sizing: border-box;
	padding: 0 15px;
	background: url("../../assets/blue-dropdown-arrow.png") no-repeat right
		white;
	background-size: 5%;
	background-position: right 3% bottom 50%;
	-webkit-appearance: none;
}

.contact-form div textarea {
	width: 40%;
	height: 12vh;
	background-color: white;
	border: 2px solid #316cf6;
	border-radius: 10px;
	color: #444;
	font-family: "Nunito Sans", sans-serif;
	font-family: "Poppins", sans-serif;
	font-size: 0.8rem;
	box-sizing: border-box;
	padding: 10px;
}

.contact-form button {
	margin-top: 1vh;
	background-color: #316cf6;
	color: white;
	width: 25%;
	height: 7vh;
	font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
	.contact-form {
		width: 90%;
	}
    .contact-page-container h2 {
        font-size: 1.8rem;
    }
    .contact-page-container p {
        font-size: .9rem;
    }
	.contact-form div label,
	.contact-form div input,
	.contact-form div select,
	.contact-form div textarea {
		width: 80%;
        font-size: .8rem;
	}
    .contact-form button {
        width: 30%;
    }
}
